<template>
  <div id="pay">
    <MarketingNav></MarketingNav>
    <section id="header" class="relative px-4 pt-16 mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full">
      <div class="max-w-xl mx-auto lg:max-w-screen-xl">
        <div class="mb-16 lg:max-w-lg lg:mb-0">
          <div class="max-w-xl mb-6">
            <h2 class="animate__animated animate__slideInLeft max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Quotation, Invoicing, Payments<br class="hidden md:block" />
              should never
              <span class="inline-block text-green-500">be a hassle.</span>
            </h2>
            <p class="animate__animated animate__slideInLeft text-base text-gray-700 md:text-lg">
              DuitCount is a Cloud Invoicing, Accounting platform <br/> made for business owners, accountants of all sizes.<br/>
            </p>
          </div>
          <div class="flex items-center animate__animated animate__slideInLeft">
            <a
                href="#simplePrice"
                class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none"
            >
              Get started
            </a>
            <router-link :to="{name: 'Features'}" aria-label="" class="inline-flex items-center font-semibold transition-colors duration-200 text-green-500 hover:text-deep-800">Learn more</router-link>
          </div>
        </div>
      </div>
      <div style="z-index: -1" class="relative animate__animated animate__slideInUp flex justify-center h-full lg:w-2/3 xl:w-1/2 lg:absolute lg:justify-start lg:bottom-0 lg:right-0 lg:items-end">
        <div style="width: 50px; height: 50px;top: 20px; left:50px;" class="bubble animate__animated animate__delay-1s animate__fadeInBottomRight hidden md:block absolute bg-green-500">
          <div style="width: 80%; height: 80%; margin-top: 10%" class="bubble bg-white mx-auto my-auto text-center"></div>
        </div>
        <div style="width: 100px; height: 100px;top: 55px; left:-20px;" class="bubble animate__animated animate__delay-1s animate__fadeInBottomRight hidden md:block absolute bg-green-500">
          <div style="width: 80%; height: 80%; margin-top: 10%" class="bubble bg-white mx-auto my-auto text-center"></div>
        </div>
        <div style="width: 250px; height: 250px;bottom: -120px; right:50px;" class="bubble animate__animated animate__delay-1s animate__fadeInBottomLeft hidden md:block absolute bg-green-500">
          <div style="width: 80%; height: 80%; margin-top: 10%" class="bubble bg-white mx-auto my-auto text-center"></div>
        </div>
        <img style="z-index: 0; box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.6);"
             :src="require('@/assets/img/frame_safari_dark.png')"
             class="overflow-x-hidden object-cover object-top w-full h-64 max-w-xl -mb-16 rounded lg:ml-64 xl:ml-8 lg:-mb-10 xl:-mb-10 xxl:-mb-20 lg:h-auto lg:max-w-screen-md" alt="" />
      </div>
    </section>
    <AdsFeatureSection></AdsFeatureSection>

    <section id="notable_features" class="my-20">
      <div class="w-full text-center">
        <h1 class="animate__animated animate__slideInTop max-auto mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
          Notable Features of Duitcount
        </h1>
      </div>
      <div class="flex justify-center gap-4 font-bold text-lg text-center md:text-left leading-tight mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div class="border-r-2 pr-3 cursor-pointer" v-on:click="changeFeature(features[0])" :class="[features[0].is_active ? 'border-black text-black selected-feature' : 'border-gray-500 text-gray-500']">Scan To Pay</div>
        <div class="border-r-2 pr-3 cursor-pointer" v-on:click="changeFeature(features[1])" :class="[features[1].is_active ? 'border-black text-black selected-feature' : 'border-gray-500 text-gray-500']">Work In a Team</div>
        <div class="cursor-pointer" v-on:click="changeFeature(features[2])" :class="[features[2].is_active ? 'border-black text-black selected-feature' : 'border-gray-500 text-gray-500']">Email Reminders</div>
      </div>
    </section>

    <ScanToPay v-show="features[0].is_active"></ScanToPay>
    <WorkInTeam v-show="features[1].is_active"></WorkInTeam>
    <EmailReminder v-show="features[2].is_active"></EmailReminder>

    <Security></Security>

    <section id="simplePrice" class="px-4 bg-green-500 w-full py-16 mx-auto lg:py-20" style="z-index: 1;box-shadow: -10px 0px 20px -6px rgba(0,0,0,0.6);">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
          Simple Pricing<span class="text-green-500">.</span>
        </h2>
      </div>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg mx-auto grid lg:grid-cols-3">
        <div class="flex justify-center items-center lg:order-1 order-2">
          <div class="max-w-xl mb-6 text-center">
            <div class="text-4xl text-white line-through leading-tight">RM30</div>
            <div class="text-6xl text-white font-bold flex justify-center">RM12 <span class="text-2xl mt-3">50</span></div>
            <div class="text-white font-bold text-xl">Per User | Per Month</div>
            <a href="https://app.duitcount.my/auth/register" class="font-bold mt-5 md:ml-3 inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-green-500 transition duration-200 rounded shadow-md bg-white hover:bg-green-400 hover:text-white focus:shadow-outline focus:outline-none">
              TRY NOW
            </a>
          </div>
        </div>

        <div class="flex lg:col-span-2 lg:order-2 order-1 lg:mb-0 mb-6">
          <div class="grid grid-rows-none gap-0">
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Unlimited Invoice
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Unlimited Quotations
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Unlimited Customers
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Unlimited Suppliers and Payments
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Unlimited Invoice & Quotation Templates
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Financial Reports
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              MULTI Team Members and Permissions
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              600/ month Email Quota
            </h6>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row text-white md:text-2x1 text-xl leading-tight items-center">
              <div class="flex items-center justify-center w-10 h-10">
                <svg class="w-8 text-white" stroke="currentColor" viewBox="0 0 24 24">
                  <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                </svg>
              </div>
              Get Paid Quicker with Payment Gateway Integrations
            </h6>
          </div>

        </div>
      </div>
    </section>

    <section id="sign_up" class="py-16">
      <SignUpBox :marketingButton="'Enquire'" :marketingTitle="'Want to know more about DuitCount'" :marketingDescription="'Leave your email here and our friendly team will get back to you as soon as possible'"></SignUpBox>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/components/Footer";
import SignUpBox from "@/views/components/SignUpBox";
import Security from "@/views/components/Security";
import EmailReminder from "@/views/components/EmailReminder";
import ScanToPay from "@/views/components/ScanToPay";
import AdsFeatureSection from "@/views/components/AdsFeatureSection";
import MarketingNav from "@/views/components/MarketingNav";
import WorkInTeam from "@/views/components/WorkInTeam";

export default {
  name: "PricingPay",
  components: {
    WorkInTeam,
    MarketingNav,
    Footer,
    SignUpBox, Security, EmailReminder, ScanToPay, AdsFeatureSection},
  data () {
    return {
      features: [
        {id: 1, is_active: true},
        {id: 2, is_active: false},
        {id: 3, is_active: false},
      ]
    }
  },
  mounted() {

  },
  methods: {
    changeFeature (feature) {
      this.features.forEach((oriFeature) => {
        if (feature.id === oriFeature.id) {
          oriFeature.is_active = true;
        }
        else {
          oriFeature.is_active = false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.selected-feature {
  text-decoration: none;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 5px;
    background: #4CAF50;
    bottom: -4px;
    width: 90%;
    left: 0;
  }
}
.bubble {
  clip-path: circle(50% at 50% 50%);
}
</style>
