<template>
  <div class="py-8 container mx-auto">
    <div class="my-8 flex flex-wrap md:items-center w-full">
      <div class="bg-green-600 shadow-lg rounded w-full md:w-1/2 z-10">
        <div class="py-8 text-center text-white font-semibold text-xl uppercase">Premium</div>
        <hr class="py-0 my-0 border border-green-700">
        <div class="py-8">
          <div class="text-center justify-center my-12 relative">
            <div class="text-6xl text-white">STAY TUNE!</div>
            <p class="text-white">Get notified from our social page</p>
            <div class="flex items-center justify-center mt-1 space-x-3">
              <a href="https://www.facebook.com/duitcount" class="text-white transition-colors duration-300 hover:text-green-400">
                <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
                  <path
                      d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
                  ></path>
                </svg>
              </a>
              <a href="/" class="text-white transition-colors duration-300 hover:text-green-400">
                <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
                  <path
                      d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
                  ></path>
                </svg>
              </a>
              <a href="/" class="text-white transition-colors duration-300 hover:text-green-400">
                <svg viewBox="0 0 30 30" fill="currentColor" class="h-6">
                  <circle cx="15" cy="15" r="4"></circle>
                  <path
                      d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
                  ></path>
                </svg>
              </a>
            </div>
            
          </div>
          <!-- <div class="py-2 text-center">
            <div class="font-semibold text-white line-through">RM29.00</div>
            <div class="text-4xl font-semibold text-white">RM12.50</div>
            <div class="text-center text-xs text-white">/ Team Member / Month</div>
          </div>
          <div class="mt-8 mx-auto w-2/3">
            <div class="flex items-center mb-4">
                <span class="inline-flex bg-green-lighter rounded-full mr-4 p-1">
                 <img class="m-1" :src="require('@/assets/img/icons/star.svg')"/>
                </span>
              <span class="text-white">All Features in Free</span>
            </div>
            <div class="flex items-center mb-4">
                <span class="inline-flex bg-green-lighter rounded-full mr-4 p-1">
                 <img class="m-1" :src="require('@/assets/img/icons/mail.svg')"/>
                </span>
              <span class="text-white">X10 Monthly Email Quota (600 / Month)</span>
            </div>
            <div class="flex items-center mb-4">
                <span class="inline-flex bg-green-lighter rounded-full mr-4 p-1">
                 <img class="m-1" :src="require('@/assets/img/icons/credit-card.svg')"/>
                </span>
              <span class="text-white">Get Paid Quicker with our Payment Gateway Integrations</span>
            </div>
            <div class="flex items-center mb-4">

                <span class="inline-flex bg-green-lighter rounded-full mr-4 p-1">
                 <img class="m-1" :src="require('@/assets/img/icons/users.svg')"/>
                </span>
              <span class="text-white">Multi Team Members and Permissions</span>
            </div>
          </div> -->
        </div>
        <a href="https://app.duitcount.my/auth/register">
          <div class="btn-shiny relative py-8 m-3 bg-green-400 text-white rounded shadow text-center uppercase font-bold flex items-center justify-center hover:shadow-xl hover:bg-green-500"><span>Create an account</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="icon fill-current w-4 h-4 ml-2">
              <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
            </svg>
          </div>
        </a>
      </div>
      <div class="bg-gray-50 rounded shadow-lg w-5/6 md:w-1/2 z-0 mx-auto -mt-1 md:-mt-0 md:-ml-1">
        <div class="py-8 text-center text-indigo-lightest font-semibold uppercase">Free</div>
        <hr class="mb-8 my-0 border border-indigo-dark opacity-25">
        <div class="w-2/3 mx-auto">
          <p class="text-center text-sm text-indigo-lightest opacity-75 leading-normal">Everything you need to run your business.</p>
        </div>
        <div class="py-8">
          <table class="w-3/4 mx-auto text-indigo-lightest text-center" style="border-collapse: collapse;">
            <tbody>
            <tr>
              <td class="px-2 py-4 border border-indigo-dark">Unlimited Invoices</td>
              <td class="px-2 py-4 border border-indigo-dark">Unlimited Quotations</td>
            </tr>
            <tr>
              <td class="px-2 py-4 border border-indigo-dark">Unlimited Customers</td>
              <td class="px-2 py-4 border border-indigo-dark">Unlimited Supplier & Payments</td>
            </tr>
            <tr>
              <td class="px-2 py-4 border border-indigo-dark">Financial Reports</td>
              <td class="px-2 py-4 border border-indigo-dark">Invoice & Quotation Templating</td>
            </tr>
            <tr>
              <td class="px-2 py-4 border border-indigo-dark">1 User Account Access</td>
              <td class="px-2 py-4 border border-indigo-dark">60 Emails Quota / Month</td>
            </tr>
            </tbody>
          </table>
        </div>
        <a href="https://app.duitcount.my/auth/register">
          <div class="py-8 opacity-50 text-grey-500 rounded rounded-t-none text-center uppercase font-bold flex items-center justify-center"><span>Start A Free Account</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="icon fill-current w-4 h-4 ml-2">
              <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" class="heroicon-ui"></path>
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceSection"
}
</script>

<style scoped>

/* 11 */
.btn-shiny {
  overflow: hidden;
  transition: all 0.3s ease;
}
.btn-shiny:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: rgba(255,255,255,0.6);
  animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-shiny:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(255,255,255,.2),
  inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}


@-webkit-keyframes shiny-btn1 {
  0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
  80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
  81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
  100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

</style>
