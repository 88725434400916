<template>
  <div class="pricing">
    <Nav></Nav>
    <section id="header" class="px-4 pt-20 pb-10 mx-auto items-center text-center">
      <h1 class="animate__animated animate__slideInTop max-auto mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Pricing<span class="text-green-500">.</span>
      </h1>
      <p class="animate__animated animate__slideInTop text-base text-gray-700 md:text-lg">
        Straight-forward Pricing with great support.
      </p>
    </section>

    <div class="text-green-400 text-center text-xl flex flex-col" style="letter-spacing: 2em;">
      <span>•</span>
      <span>•</span>
      <span>•</span>
    </div>

    <PriceSection></PriceSection>

    <section id="sign_up" class="py-16 mt-20">
      <SignUpBox></SignUpBox>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from "@/views/components/Nav";
import SignUpBox from "@/views/components/SignUpBox";
import Footer from "@/views/components/Footer";
import PriceSection from "@/views/components/PriceSection";
export default {
  components: {PriceSection, Footer, SignUpBox, Nav}
}
</script>
