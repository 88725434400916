<template>
  <section id="AdsFeature" class="px-4 bg-green-500 w-full py-16 mx-auto lg:py-20" style="z-index: 1;box-shadow: -10px 0px 20px -6px rgba(0,0,0,0.6);">
    <div class="grid max-w-screen-lg mx-auto">
      <div class="flex justify-center">
        <div class="grid grid-cols-none md:grid-cols-3 gap-4">
          <div class="grid grid-flow-row">
            <div class="flex items-center w-32 md:w-24 lg:w-32 bg-transparent mb-6 mx-auto">
              <img src="@/assets/img/icons/payments.svg">
            </div>
            <h2 class="h-10 max-w-lg font-sans lg:text-xl md:text-lg text-2xl text-center font-bold leading-none tracking-tight text-white sm:text-2xl md:mx-auto ">
              Get Paid Faster
            </h2>
            <p class="h-16 md:mx-auto text-center font-sans text-white lg:px-7">Create and send unlimited quotations and invoices with QR codes to get paid faster.</p>
          </div>

          <div class="grid grid-flow-row">
            <div class="flex items-center w-32 md:w-24 lg:w-32 bg-transparent mb-6 mx-auto">
              <img src="@/assets/img/icons/accounting.svg">
            </div>
            <h2 class="h-10 max-w-lg font-sans lg:text-xl md:text-lg text-2xl font-bold text-center leading-none tracking-tight text-white sm:text-2xl md:mx-auto">
              Accounting All in One Place
            </h2>
            <p class="h-16 md:mx-auto text-center font-sans text-white lg:px-7">Collect payments, store payment info and send receipts all in one place.</p>
          </div>

          <div class="grid grid-flow-row">
            <div class="flex items-center w-32 md:w-24 lg:w-32 bg-transparent mb-6 mx-auto">
              <img src="@/assets/img/icons/reporting.svg">
            </div>
            <h2 class="h-10 max-w-lg font-sans lg:text-xl md:text-lg text-2xl font-bold text-center leading-none tracking-tight text-white sm:text-2xl md:mx-auto">
              Better Business Insight
            </h2>
            <p class="h-16 md:mx-auto text-center font-sans text-white lg:px-7">Create reports and collaborate with your team to know your business health with multiple reports</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AdsFeatureSection"
}
</script>

<style scoped>

</style>
